import React from "react";
import { Link }  from "react-router-dom";

const logo = () => (
    <Link to="/">
        <h1 className="logo">
            <span>Editora MaujorTec</span>
        </h1>
    </Link>
);

export default logo;