import React    from "react";

const Rodape = () => {
    return (
        <footer className="rodape">
            Conteúdo cedido pela Editora Novatec &#8212; &copy; Copyright 2021.
        </footer>
    );
};

export default Rodape;